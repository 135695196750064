import React, { useContext } from "react";
import { UserInteractionsContext } from "../DomainDesign/Context/UserInteractionsContext";
import useUrl from "../utils/hooks/useUrl";
import { navButtons, journeyButtons } from "./NavButtons";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

function FloatingButtonList({ onClose }) {
  const [userInteractions] = useContext(UserInteractionsContext);
  const { simulador } = userInteractions;
  const [handleClick] = useUrl();

  return (
    <div className="pt-2" onClick={onClose}>
      <List>
        {navButtons.map((btn) => (
          <ListItem button key={btn.label} onClick={() => handleClick(btn.url)}>
            <ListItemIcon>
              <Badge
                variant="dot"
                color="secondary"
                invisible={simulador !== btn.stage}
              >
                {btn.icon}
              </Badge>
            </ListItemIcon>
            <ListItemText>{btn.label}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Divider variant="middle" />
      <List>
        {journeyButtons.map((btn) => (
          <ListItem button key={btn.label} onClick={() => handleClick(btn.url)}>
            <ListItemIcon>
              <Badge
                variant="dot"
                color="secondary"
                invisible={simulador !== btn.stage}
              >
                {btn.icon}
              </Badge>
            </ListItemIcon>
            <ListItemText>{btn.label}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Hidden mdUp>
        <List>
          <ListItem button key="close-button" onClick={onClose}>
            <ListItemIcon>
              <CloseRoundedIcon />
            </ListItemIcon>
            <ListItemText>Cerrar</ListItemText>
          </ListItem>
        </List>
      </Hidden>
    </div>
  );
}

export default FloatingButtonList;
