import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { UserInteractionsContext } from "../../DomainDesign/Context/UserInteractionsContext";
import { UserContext } from "../../DomainDesign/Context/UserContext";
import postData from "../../services/postData";

const getSimulador = (url) => {
  if (url.startsWith("/simulador-para-cuanto-me-alcanza"))
    return "affordability";
  if (url.startsWith("/simulador-estoy-listo")) return "readiness";
  if (url.startsWith("/simulador-creditos-hipotecarios")) return "mortgage";
  return "homebuyer";
};

const stageCompleted = (loc) => {
  if (
    loc === "/simulador-para-cuanto-me-alcanza/gastos" ||
    loc === "/simulador-para-cuanto-me-alcanza/resultados-simulacion"
  )
    return 1;
  if (
    loc === "/simulador-estoy-listo/antiguedad-laboral" ||
    loc === "/simulador-estoy-listo/resultados-simulacion"
  )
    return 2;
  if (
    loc === "/simulador-creditos-hipotecarios/mi-banco" ||
    loc === "/simulador-creditos-hipotecarios/resultados-simulacion"
  )
    return 3;
  return 0;
};

const simuladorStarted = (url) => {
  if (
    url === "/simulador-para-cuanto-me-alcanza" ||
    url === "/simulador-estoy-listo" ||
    url === "/simulador-creditos-hipotecarios"
  )
    return ReactGA.event({
      category: getSimulador(url),
      action: "started",
    });
  return null;
};

const simuladorCompleted = (url) => {
  if (
    url === "/simulador-para-cuanto-me-alcanza/gastos" ||
    url === "/simulador-estoy-listo/antiguedad-laboral" ||
    url === "/simulador-creditos-hipotecarios/mi-banco"
  )
    return ReactGA.event({
      category: getSimulador(url),
      action: "completed",
    });
  return null;
};

export default function useUrl() {
  const [userInteractions, setUserInteractions] = useContext(
    UserInteractionsContext
  );

  const [user] = useContext(UserContext);

  let history = useHistory();
  let location = useLocation();

  const handleClick = (url) => {
    const lastUrlUpdate = { ...userInteractions.lastUrl };
    lastUrlUpdate[getSimulador(url)] = url;

    history.push(url);

    simuladorStarted(url);

    simuladorCompleted(url);

    postData(user, userInteractions);

    setUserInteractions((prevUserInteractions) => ({
      ...prevUserInteractions,
      interaccion: prevUserInteractions.interaccion + 1,
      url: location.pathname,
      simulador: getSimulador(url),
      lastUrl: { ...lastUrlUpdate },
      navbarOpen: false,
      homebuyStep:
        url !== "/" ? stageCompleted(url) : prevUserInteractions.homebuyStep,
    }));
  };

  return [handleClick];
}
