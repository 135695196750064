import React, { createContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import getUser from "../User";
import { http } from "../../services/http";
import useLocalStorage from "../../utils/hooks/useLocalStorage";

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const [user, setUser] = useLocalStorage("userData", getUser());

  useEffect(() => {
    async function getIp() {
      try {
        const ip = await http.get(
          "https://ipinfo.io/json?token=3b3e6e0bb20e34"
        );
        return ip;
      } catch (error) {}
    }

    getIp()
      .then((res) =>
        setUser((prevUser) => ({
          ...prevUser,
          ip: res.data.ip,
          ciudad: res.data.city,
          region: res.data.region,
          country: res.data.country,
          postal: res.data.postal,
          isMobile: isMobile,
        }))
      )
      .catch(() =>
        setUser((prevUser) => ({
          ...prevUser,
          ip: "",
          ciudad: "",
          region: "",
          country: "",
          postal: "",
          isMobile: isMobile,
        }))
      );
  }, []);

  // Provider

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};
