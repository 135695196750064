import React, { createContext } from "react";
import getUserInteractions from "../UserInteractions";
import useLocalStorage from "../../utils/hooks/useLocalStorage";

export const UserInteractionsContext = createContext();

export const UserInteractionsProvider = (props) => {
  const [userInteractions, setUserInteractions] = useLocalStorage(
    "userInteractions",
    getUserInteractions()
  );

  // Provider
  return (
    <UserInteractionsContext.Provider
      value={[userInteractions, setUserInteractions]}
    >
      {props.children}
    </UserInteractionsContext.Provider>
  );
};
