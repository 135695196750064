import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import HHLogo from "../common/hhLogo.svg";

function Navbar() {
  return (
    <div>
      <AppBar position="static" color="inherit">
        <Toolbar variant="dense">
          <div className="flex justify-center w-full">
            <a
              href="https://www.honest-home.io/"
              className="flex flex-row items-center"
            >
              <IconButton edge="start" color="inherit" aria-label="menu">
                <div className="h-8 w-8">
                  <img src={HHLogo} alt="Honest Home"></img>
                </div>
              </IconButton>
              <p className="flex text-xl font-bold"> Honest Home </p>
            </a>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navbar;
