// User interactions schema & initialization

const userInteractions = {
  interaccion: 0,
  url: "/",
  // homebuyer || affordability || readiness || mortgage || advisor
  simulador: "homebuyer",

  lastUrl: {
    homebuyer: "/",
    advisor: "",
    affordability: "/simulador-para-cuanto-me-alcanza",
    readiness: "/simulador-estoy-listo",
    mortgage: "/simulador-creditos-hipotecarios",
  },
  // is navbar open?
  navbarOpen: false,
  homebuyStep: 0,
};

export default function getUserInteractions() {
  return userInteractions;
}
