import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
import ReactGA from "react-ga";

function VirtualAdvisor() {
  const handleClick = () => {
    ReactGA.event({
      category: "virtual advisor",
      action: "opened",
    });
  };

  return (
    <div onClick={handleClick}>
      <MessengerCustomerChat
        pageId="112255053854855"
        appId="2879731132258127"
      />
    </div>
  );
}

export default VirtualAdvisor;
