import React from "react";
import Fab from "@material-ui/core/Fab";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

function FloatingButton({ onClick }) {
  return (
    <div className="fixed z-50 bottom-4 left-4 w-16 h-16 rounded-full block">
      <Fab color="secondary" aria-label="edit" onClick={onClick}>
        <MenuRoundedIcon />
      </Fab>
    </div>
  );
}

export default FloatingButton;
