import React from "react";
import { Route, Switch } from "react-router-dom";
import { UserContextProvider } from "./DomainDesign/Context/UserContext";
import { UserInteractionsProvider } from "./DomainDesign/Context/UserInteractionsContext";
import loadable from "@loadable/component";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import useTheme from "./utils/hooks/useTheme";
import FloatingMenu from "./Navbar/FloatingMenu";
import Sidebar from "./Navbar/Sidebar";
import Navbar from "./Navbar/Navbar";
import VirtualAdvisor from "./common/VirtualAdvisor";

// Code spliting

const HomebuyerJourney = loadable(() =>
  import("./HomebuyerJourney/HomebuyerJourney")
);

const Affordability = loadable(() => import("./Affordability/Affordability"));

const Readiness = loadable(() => import("./Readiness/Readiness"));

const MortgageSimulator = loadable(() =>
  import("./MortgageSimulator/MortgageSimulator")
);

const NotFound = loadable(() => import("./common/NotFound"));

// Routing

function App() {
  return (
    /* providers */
    <UserInteractionsProvider>
      <UserContextProvider>
        <div className="App">
          {/* provide theme */}
          <ThemeProvider theme={useTheme()}>
            <CssBaseline />

            <div className="flex flex-row">
              <div>
                <Sidebar />
              </div>
              <main className="w-screen">
                <Navbar />
                {/* routing */}

                <Switch>
                  <Route exact path="/">
                    <HomebuyerJourney />
                  </Route>

                  <Route path="/simulador-por-donde-comienzo">
                    <HomebuyerJourney />
                  </Route>

                  <Route path="/simulador-para-cuanto-me-alcanza">
                    <Affordability />
                  </Route>

                  <Route path="/simulador-estoy-listo">
                    <Readiness />
                  </Route>

                  <Route path="/simulador-creditos-hipotecarios">
                    <MortgageSimulator />
                  </Route>

                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
                {/* routing */}
              </main>
              {/* Floating Menu*/}
              <FloatingMenu />
              {/* virtual advisor */}
              <VirtualAdvisor />
            </div>
            {/* Floating Menu*/}
          </ThemeProvider>
        </div>
        {/* providers */}
      </UserContextProvider>
    </UserInteractionsProvider>
  );
}

export default App;
