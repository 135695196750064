import { http } from "./http";

export default function postData(user, userInteractions) {
  const data = {
    single: user.single,
    precio: user.precio,
    enganche: user.enganche,
    plazo: user.plazo,
    ingresos: user.ingresos,
    gastos: user.gastos,
    gastosIniciales: user.gastosIniciales,
    condicionLaboral: user.condicionLaboral,
    comprobanteIngresos: user.comprobanteIngresos,
    antiguedadLaboral: user.antiguedadLaboral,
    seguridadSocial: user.seguridadSocial,
    usoCreditoBanco: user.usoCredito.banco,
    usoCreditoDepartamento: user.usoCredito.departamento,
    usoCreditoAuto: user.usoCredito.auto,
    consultaHistorialCrediticio: user.consultaHistorialCrediticio,
    scoreCrediticio: user.scoreCrediticio,
    banco: user.banco,
    ip: user.ip,
    ciudad: user.ciudad,
    region: user.region,
    country: user.country,
    postal: user.postal,
    isMobile: user.isMobile,
    email: user.email,
    interaccion: userInteractions.interaccion,
    url: userInteractions.url,
    simulador: userInteractions.simulador,
    lastUrlHomebuyer: userInteractions.lastUrl.homebuyer,
    lastUrlAffordability: userInteractions.lastUrl.affordability,
    lastUrlReadiness: userInteractions.lastUrl.readiness,
    lastUrlMortgage: userInteractions.lastUrl.mortgage,
    homebuyStep: userInteractions.homebuyStep,
  };

  // const data2 = {
  //   single: true,
  //   precio: 1500000,
  //   enganche: 150000,
  //   plazo: 20,
  //   ingresos: 60000,
  //   gastos: 30000,
  //   gastosIniciales: 35000,
  //   condicionLaboral: "empleado",
  //   comprobanteIngresos: 0,
  //   antiguedadLaboral: 2,
  //   seguridadSocial: "sinSS",
  //   usoCreditoBanco: false,
  //   usoCreditoDepartamento: true,
  //   usoCreditoAuto: true,
  //   consultaHistorialCrediticio: true,
  //   scoreCrediticio: "bueno",
  //   banco: "hsbc",
  //   ip: "194.41.112.6",
  //   ciudad: "Mexico City",
  //   region: "Mexico City",
  //   country: "MX",
  //   postal: "03020",
  //   isMobile: false,
  //   email: "prueaba@test.com",
  //   interaccion: 145,
  //   url: "/simulador-creditos-hipotecarios",
  //   simulador: "mortgage",
  //   lastUrlHomebuyer: "/",
  //   lastUrlAffordability: "/simulador-para-cuanto-me-alcanza",
  //   lastUrlReadiness: "/simulador-estoy-listo",
  //   lastUrlMortgage: "/simulador-creditos-hipotecarios/precio",
  //   homebuyStep: 0,
  // };

  http
    .post(
      "https://6gnx1u2u4f.execute-api.us-east-1.amazonaws.com/api/save-user",
      data
    )
    .then(function (response) {})
    .catch(function (error) {});
}
