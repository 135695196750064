import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme } from "@material-ui/core/styles/";

export default function useTheme() {
  // dark mode
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        // Set color theme
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            // Purple and green play nicely together.
            // main: "#ede7f6",
            main: "#5AAA95",
          },
          secondary: {
            // This is green.A700 as hex.
            main: "#1E90FF",
          },
        },
      }),
    [prefersDarkMode]
  );
  return theme;
}
