// User schema

const user = {
  // applied alone or with couple
  single: true,

  // MORTGAGE PRICES
  //all prices are in MXP
  precio: 1500000,
  enganche: 150000,
  plazo: 20,

  // FAMILY FINANCES
  ingresos: 50000,
  gastos: 30000,
  gastosIniciales: 750000,

  // EMPLOYMENT
  // labor conditions: empleado || independiente
  condicionLaboral: "empleado",
  // social security: sinSS || infonavit || fovissste
  seguridadSocial: "sinSS",
  // menos "tres" meses || "tres" meses || "seis" meses
  comprobanteIngresos: 0,
  // menos "tres" meses || "tres" meses || "seis" meses
  antiguedadLaboral: 2,

  // CREDIT HISTORY
  usoCredito: { banco: false, departamento: false, auto: false },
  consultaHistorialCrediticio: true,
  // true || false
  scoreCrediticio: "bueno",
  // bueno: 700+ || regular: 600-700 || malo: <600 o lo conoce

  // banco: banorte || bbva || citibanamex || hsbc || santander || scotiabank
  banco: "bbva",

  // LOCATION AND DEVICE
  // ip get using
  ip: "189.251.148.8",
  ciudad: "Monterrey",
  region: "Nuevo León",
  country: "MX",
  postal: 50071,
  // device: mobile || tablet || desktop
  isMobile: true,
  email: "",
};

export default function getUser() {
  return user;
}
