import React, { useState, useContext } from "react";
import { UserInteractionsContext } from "../DomainDesign/Context/UserInteractionsContext";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import FloatingButton from "./FloatingButton";
import FloatingButtonList from "./FloatingButtonList";

function FloatingMenu() {
  const [userInteractions] = useContext(UserInteractionsContext);
  const { navbarOpen } = userInteractions;
  const [state, setState] = useState({ open: navbarOpen });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ open });
  };

  return (
    <Hidden mdUp>
      <FloatingButton onClick={toggleDrawer(true)} />
      <Drawer anchor="bottom" open={state.open} onClose={toggleDrawer(false)}>
        <FloatingButtonList onClose={toggleDrawer(false)} />
      </Drawer>
    </Hidden>
  );
}

export default FloatingMenu;
